import xhr from 'xhr'

export default function makeAjax (data, callback) {
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, callback)
}
