/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import mobileNavigation from './modules/mobile-navigation'
import Swiper from 'swiper'
import notifier from './vendor/notifier'
import initToggle from './modules/toggle-container'
import toggleSub from './modules/toggle-sub-menu'
import initTilesToggle from './modules/tab-tiles-toggle.js'
import initHeaderShrink from './modules/header_shrink.js'
import debug from './modules/debug.js'
import fetchPosts from './modules/fetch-facebook'

/**
 * Ab geht die Reise
 */
domready(() => {
  if (document.getElementById('message-notifer')) {
    console.log(document.getElementById('message-notifer'))
    notifier.show(document.getElementById('message-notifer').getAttribute('data-message'), '', document.getElementById('message-notifer').getAttribute('data-status'), '', 4000)
  }
  mobileNavigation()
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  toggleSub()
  initToggle()
  initTilesToggle()
  initHeaderShrink()
  debug()

  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 5000,
    speed: 2000,
    loop: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev'
  })
// eslint-disable-next-line
  new Swiper('.leistungen-slider', {
    slidesPerView: 3,
    slidesPerColumn: 1,
    spaceBetween: 0,
    speed: 2000,
    nextButton: '.leistung-button-next',
    prevButton: '.leistung-button-prev',
    loop: true,
    breakpoints: {
      568: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  })
  fetchPosts({
    api: 'v2.8',
    pageID: 'schoene.reisen',
    endpoint: 'posts',
    accessToken: '943594269115876|25c7d1e0fb3061d77d1f6cb8d0145252',
    fields: 'full_picture,message,link,name,description,created_time,type,likes',
    limit: '10'
  }, function (err, res, statusCode) {
    if (err) {
      console.log(err)
    } else {
      console.log(res)
    }
  })
  // eslint-disable-next-line
  baguetteBox.run('.gallery', {
    buttons: true // false -> shows buttons mobile
  })
// eslint-disable-next-line
})
