// import selectAll from './select-all'
import baguetteBox from 'baguettebox.js'

// function toggleContent (boxID) {
//   let allImgContainer = selectAll('.tabs-tiles .img-tile-container div.img-box')
//   let allFaqTextContainer = selectAll('.tabs-tiles .text-content-container .text-content')
//   var i
//   if (allImgContainer != null) {
//     for (i = 0; i < allImgContainer.length; i++) {
//       let imgContainer = allImgContainer[i]
//
//       if (imgContainer.getAttribute('data-id') === boxID) {
//         imgContainer.classList.remove('hidden')
//         imgContainer.classList.add('show')
//       } else {
//         imgContainer.classList.remove('show')
//         imgContainer.classList.add('hidden')
//       }
//     }
//   }
//
//   if (allFaqTextContainer != null) {
//     for (i = 0; i < allFaqTextContainer.length; i++) {
//       let faqContainer = allFaqTextContainer[i]
//       if (faqContainer.getAttribute('data-id') === boxID) {
//         faqContainer.classList.add('show')
//         faqContainer.classList.remove('hidden')
//       } else {
//         faqContainer.classList.add('hidden')
//         faqContainer.classList.remove('show')
//       }
//     }
//   }
// }
//
// function toggleBtnActive (btn) {
//   let menuBtns = selectAll('.tabs-tiles .tab-menu .toggle-tile-link')
//   for (var i = 0; i < menuBtns.length; i++) {
//     menuBtns[i].parentElement.classList.remove('active')
//   }
//   btn.parentElement.classList.add('active')
// }

export default function initTilesToggle () {
  // let menuBtns = selectAll('.tabs-tiles .tab-menu .toggle-tile-link')
  // let activeElement = selectAll('.tabs-tiles .tab-menu li.active button')
  // if (typeof activeElement[0] !== 'undefined') {
  //   let activeBoxID = activeElement[0].getAttribute('data-id')
  //   toggleContent(activeBoxID)
  // }
  //
  // // setup event listener
  // if (menuBtns != null) {
  //   for (var i = 0; i < menuBtns.length; i++) {
  //     let btn = menuBtns[i]
  //     btn.addEventListener('click', function (event) {
  //       let boxID = event.target.getAttribute('data-id')
  //       toggleBtnActive(this)
  //       toggleContent(boxID)
  //     })
  //   }
  // }
  baguetteBox.run("div[class^='gallery_']", {
    // captions: false
  })
}
