export default function initHeaderShrink () {
  let scrollTopLimit = 250
  let headerInfo = document.querySelectorAll('header .headerInfo')
  let logoImg = document.querySelectorAll('.header-logo')
  document.addEventListener('scroll', function () {
    if (document.body.scrollTop > scrollTopLimit) {
      if (typeof headerInfo[0] !== 'undefined') {
        headerInfo[0].classList.add('header-info--hidden')
        headerInfo[0].classList.remove('header-info--show')
      }
      if (typeof logoImg[0] !== 'undefined') {
        logoImg[0].classList.add('shrink')
        logoImg[0].classList.remove('normal')
      }
    }
    if (document.body.scrollTop < scrollTopLimit) {
      if (typeof headerInfo[0] !== 'undefined') {
        headerInfo[0].classList.add('header-info--show')
        headerInfo[0].classList.remove('header-info--hidden')
      }
      if (typeof logoImg[0] !== 'undefined') {
        logoImg[0].classList.add('normal')
        logoImg[0].classList.remove('shrink')
      }
    }
  })
}
