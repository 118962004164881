/* global FB */
import getPosts from './getPagePosts'
export default function fetchPosts (options, callback) {
  window.fbAsyncInit = function () {
    FB.init({
      appId: '943594269115876',
      xfbml: false,
      version: 'v2.8'
    })
    getPosts('1466373386938120', 1)
  };
  /* eslint-disable */
  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[ 0 ];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
  /* eslint-enable  */
}

