import selectAll from './select-all'
import baguetteBox from 'baguettebox.js'

function getItemInfo (id, element) {
  let body = document.getElementById(id).innerHTML
  document.getElementById('modal-body').innerHTML = body
  baguetteBox.run('.gallerymodal')
  modal(element)
}
export function modal (elem) {
  let closeButton = selectAll('.close')
  elem.classList.add('open')
  document.body.classList.add('stop-scrolling')
  if (closeButton !== null) {
    for (var i = closeButton.length - 1; i >= 0; i--) {
      closeButton[ i ].removeEventListener('click', function () {})
      closeButton[ i ].addEventListener('click', function () {
        elem.classList.remove('open')
        document.body.classList.remove('stop-scrolling')
        if (this.classList.contains('reload')) {
          window.location.reload()
        }
      }, false)
    }
  }
}
export default function initModal () {
  let modals = selectAll('.modal-open')
  if (modals !== null) {
    for (var i = modals.length - 1; i >= 0; i--) {
      modals[ i ].removeEventListener('click', function () {})
      modals[ i ].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        let element = document.getElementById(this.getAttribute('data-target'))
        if (this.classList.contains('modal-body')) {
          getItemInfo(this.getAttribute('data-id'), element)
        } else {
          modal(element)
        }
        return false
      }, { passive: false })
    }
  }
}
